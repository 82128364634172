const COPY = {
  homepage: {
    tout: 'Start Today & Get 7 Days Free!',
    hero: {
      headline: 'Work out with us!',
      subheading: 'Anytime, anywhere',
      copy: 'Ready to boost your metabolism, tone your gorgeous muscles, and achieve your fitness goals? You’ll do it all when you sign up with Tone It Up, the leading fitness community for women. We created fun workouts, programs, and recipes that get you results on your schedule!'
    },
    panels: {
      panelOne: {
        headline: 'Personal training anywhere, anytime',
        copy: 'Achieve the results you want with inspiring and effective fitness programs and on demand workouts. Choose from classes 10 to 40 minutes long, or pick your perfect Tone It Up workout program — each designed to get you going and keep you glowing!'
      },
      panelTwo: {
        headline: 'Simple, satisfying, and super healthy recipes',
        copy: "Whether you're an avid chef or need easy-to-make options, we have recipes and meal prep tips —  for all dietary needs! Our science-based guidelines speed up your metabolism, fuel your lean muscle, and provide you the nutrients you need to glow."
      },
      panelThree: {
        headline: '100’s of on-demand workouts for every level',
        copy: 'Stay strong, healthy, and fit with unlimited access to hundreds of video-guided workouts. All your faves are here: Yoga, weight training, HIIT, cardio, barre, boxing, kettlebell, strength, and so much more!'
      },
      panelFour: {
        headline: 'Accountability and flexibility for ultimate results',
        copy: 'We’re here to support you, guide you, and cheer you on every step of your fitness journey! With hundreds of fun and challenging workouts and tasty recipes, getting fit and healthy will never be boring.'
      },
      panelFive: {
        headline: 'Connect with your #TIUteam community',
        copy: 'Join us for LIVE workouts! Connect and chat with other empowered women and your trainers. Let’s achieve our goals together!'
      }
    },
    join: {
      headline: 'Ready? 2 simple ways to join',
      copy: 'Sign up today and receive:',
      features: [
        'Effective and fun fitness programs designed by your trainers to get results',
        'Easy-to-follow progress & daily reminders to keep you accountable',
        'LIVE classes with your trainers and the supportive TIU community to stay inspired',
        'The best recipes and science-backed nutrition guidelines to feel and look amazing'
      ]
    },
    featuredHeadline: 'We’ve been featured on'
  },
  pickPlan: {
    headline: '#1 Fitness Programs For Women',
    copy: 'Join us now and unlock everything you need all in one place!',
    cta: 'Sign up',
    ctaParagraph: 'for a free account with limited access.'
  },
  signUp: {
    headline: `Sign Up For Studio Tone It Up!`,
    copy: 'Boost your metabolism, tone your gorgeous muscles, achieve your fitness goals, and see amazing results while having a blast working out with the best trainers!',
    changePlan: {
      copy: 'Change to Yearly Access to get almost half off!',
      cta: 'Switch here.'
    },
    form: {
      ctaFacebook: 'Sign up with Facebook',
      ctaNext: 'Accept & Next',
      ctaTerms: 'Terms and conditions',
      pwRequirements:
        'Password Requirements: At least 8 characters, at least 1 number and 1 special character.'
    }
  },
  paymentDetails: {
    headline: 'Payment details',
    headlineAddress: 'Billing Address',
    cta: 'Accept & Purchase',
    errors: {
      cardholderName:
        'Please enter your name as it appears on your credit card.',
      fullName: 'Please enter your full name.',
      address: 'Please enter your street address.',
      state: 'Please enter your state/province.',
      city: 'Please enter your city.',
      zipcode: 'Please enter your postal code.',
      country: 'Please enter your country'
    }
  },
  updateDetails: {
    headline: 'Billing Details',
    cta: 'Update'
  },
  reactivateDetails: {
    headline: 'Payment Details',
    cta: 'Reactivate'
  },
  logIn: {
    form: {
      headline: 'Welcome Back, Gorgeous',
      ctaLogin: 'Log in',
      ctaFacebook: 'Log in with Facebook',
      ctaForgotPassword: 'Forgot Password?',
      ctaParagraph: 'No account? Sign up',
      ctaSignUp: 'here'
    }
  },
  download: {
    headline: 'You’re All Set',
    copy: "Congrats on committing to yourself! We're going to get healthier, strong, and more confident together! Now you just need to download the app and log in. Make sure to verify your email with the link we sent you so you can access all of the amazing workouts waiting for you xxo",
    cta: 'See Your Profile'
  },
  resetPassword: {
    headline: 'Reset Your Password',
    copy: 'We’ll send you a email with a link and you’ll be able to reset your password!',
    cta: 'Send Reset Link'
  },
  createProfile: {
    headline: 'Create Your Profile',
    headlineUpdate: 'Update Your Profile',
    copy: 'Tell us a lil’ something about yourself!',
    headlineGoal: 'How many times a week do you want to work out?',
    headlineInterests: 'Select up to two interests',
    headlineWorkouts: 'Select up to two workouts',
    ctaSave: 'Save',
    form: {
      displayName: 'Username',
      firstName: 'First name',
      lastName: 'Last name',
      bio: 'Add your bio here! Need a convo starter... share a fun fact about yourself!',
      workoutGoals: ['1', '2', '3', '4', '5', '6'],
      interests: [
        'Bride-to-Be',
        'Boss Babe',
        'Travelista',
        'Foodie',
        'Stellar Student',
        'Super Mom',
        'Athlete',
        'Gym Lover',
        'Mindfulness Meditator',
        'Home Gym Hunnie',
        'Mama To Be',
        'Workout Newbie'
      ],
      workouts: [
        'Rosé curls',
        'Tabata',
        'Toning',
        'Cycling',
        'Kettlebells',
        'HIIT',
        'Strength Training',
        'Swimming',
        'Kickboxing',
        'Spinning * Biking',
        'Hiking',
        'Cardio',
        'Dance',
        'Barre',
        'Pilates',
        'Weights',
        'Yoga',
        'Running'
      ]
    }
  },
  profile: {
    congratsOverlay: {
      headline: 'Congrats Babe!',
      copy: "Your payment has been confirmed! Now let's create your profile!",
      cta: 'Create your profile'
    },
    ctaEditProfile: 'Edit Profile',
    ctaUpdate: 'Edit plan',
    headlineGetApp: 'Get the app',
    deleteAccount: {
      headline: 'Cancel your plan'
    },
    headlineWorkouts: 'Your Weekly Workout Goal',
    headlineNoWorkouts:
      'No workout goals, babe! Click "update" below to add some!',
    headlineNoInterests:
      'No interests, babe! Click "update" below to add some!',
    headlineInterests: 'Your Interests & Workouts',
    headlineBilling: 'Manage Plan & Billing',
    headlinePlan: 'Billing & frequency',
    headlineSubscribed: 'Subscribed through',
    headlineScheduled: 'Next scheduled bill'
  },
  managePlan: {
    headline: 'Manage Plan & Billing',
    copy: 'Your Studio Tone It Up app plan looks strong!',
    apple: {
      paragraph: "You're currently subscribed through Apple.",
      buttonUrl: 'https://finance-app.itunes.apple.com/account/subscriptions',
      buttonCta: 'Manage in Apple App Store'
    },
    android: {
      paragraph: "You're currently subscribed through Google.",
      buttonUrl: 'https://play.google.com/store/account/subscriptions',
      buttonCta: 'Manage in Google Play Store'
    },
    whitelisted: {
      paragraph: 'You have access through Tone It Up.'
    },
    freetrial: {
      cta: 'Accept & Next'
    },
    freetrial30: {
      paragraph: 'Enjoy free premium access for 30 days'
    },
    freetrial14: {
      paragraph: 'Enjoy free premium access for 14 days'
    },
    currentPlan: {
      headline: 'Current Plan & Billing',
      headlineCard: 'Your Plan',
      cta: 'Manage in Apple App Store'
    },
    cancelPlan: {
      cta: 'Cancel Plan',
      ctaParagraph: 'your subscription',
      copyOverlay:
        'If you are sure you want to cancel your subscription, please click',
      ctaOverlay: 'here.'
    },
    billingInfo: {
      headline: 'Change Billing Information',
      copy: 'Current payment method:',
      cta: 'Edit',
      addCreditCardCTA: 'Add Card',
      error: "You currently don't have a card on file."
    },
    paymentHistory: {
      headline: 'Payment History',
      headlineLast: 'Last Payment:',
      headlineOld: 'View older payments',
      buttonMore: 'View more',
      error: "You don't currently have a plan."
    },
    reactivatePlan: {
      cta: 'Reactivate',
      ctaParagraph: 'your subscription.'
    },
    changePlan: {
      buttonCTA: 'Change Plan',
      step1Heading: 'Select Plan',
      step2Heading: 'Confirm Change',
      step2HeadingBilling: 'Billing & frequency for new plan',
      step2HeadingScheduled: 'Next scheduled bill',
      step3Heading: "Great! You're all set.",
      step3Paragraph:
        'Your new plan details can be found in your Manage Plan & Billing Section.'
    }
  },
  navigation: {
    links: {
      'My Profile': '/secure/profile',
      'Manage Plans & Billing': '/secure/profile/manage'
    },
    defaultUserGreeting: 'Babe',
    ctaLogin: 'Log in',
    ctaSignUp: 'Start Free Trial!',
    ctaSignOut: 'Sign Out'
  },
  coupon: {
    notApplied: {
      headline: 'Have a code?',
      copy: 'Enter your code here and press enter to apply.'
    },
    applied: {
      headline: 'Your code was applied.',
      tagline: 'Keep the good vibes going! Your discount has been applied.',
      success: 'Applied'
    }
  },
  carousels: {
    testimonials: {
      headline: 'Check out these amazing Tone It Up Transformations!',
      slides: [
        {
          headline:
            'Studio Tone It Up is a fun way to stay fit from home without breaking the bank!',
          author: 'Elise'
        },
        {
          headline:
            'All of the workouts were quick and effective! My proudest moment was seeing how much endurance & stamina I started building.',
          author: 'Brianna'
        },
        {
          headline:
            'Studio Tone It Up is exactly what I need, when I need it. Now that I’m expecting, I love the Pregnancy section!',
          author: 'Terra'
        },
        {
          headline:
            "It's built-in accountability to get up, get out, and get moving!",
          author: 'Tabitha'
        },
        {
          headline:
            'I noticed an increase in my muscle tone, a significant decrease in belly bloat and shrinkage in my waistline.',
          author: 'Denielle'
        }
      ]
    }
  },
  interest: [
      {
        "id" : 3,
        "name" : "Bride-to-Be",
        "slug" : "bride-to-be"
      },
      {
        "id" : 5,
        "name" : "Boss Babe",
        "slug" : "boss-babe"
      },
      {
        "id" : 10,
        "name" : "Travelista",
        "slug" : "travelista"
      },
      {
        "id" : 15,
        "name" : "Foodie",
        "slug" : "foodie"
      },
      {
        "id" : 17,
        "name" : "Stellar Student",
        "slug" : "stellar-student"
      },
      {
        "id" : 18,
        "name" : "Super Mom",
        "slug" : "super-mom"
      },
      {
        "id" : 19,
        "name" : "Athlete",
        "slug" : "athlete"
      },
      {
        "id" : 21,
        "name" : "Gym Lover",
        "slug" : "gym-lover"
      },
      {
        "id" : 22,
        "name" : "Mindfulness Meditator",
        "slug" : "mindfulness-meditator"
      },
      {
        "id" : 23,
        "name" : "Home Gym Hunnie",
        "slug" : "home-gym-hunnie"
      },
      {
        "id" : 24,
        "name" : "Mama To Be",
        "slug" : "mama-to-be"
      },
      {
        "id" : 25,
        "name" : "Workout Newbie",
        "slug" : "workout-newbie"
      }
  ],
  workouts: [
        {
          "id" : 2,
          "name" : "Running",
          "slug" : "running"
        },
        {
          "id" : 3,
          "name" : "Yoga",
          "slug" : "yoga"
        },
        {
          "id" : 5,
          "name" : "Pilates",
          "slug" : "pilates"
        },
        {
          "id" : 6,
          "name" : "Barre",
          "slug" : "barre"
        },
        {
          "id" : 7,
          "name" : "Dance",
          "slug" : "Dance"
        },
        {
          "id" : 8,
          "name" : "Cardio",
          "slug" : "cardio"
        },
        {
          "id" : 11,
          "name" : "Kickboxing",
          "slug" : "kickboxing"
        },
        {
          "id" : 13,
          "name" : "Strength Training",
          "slug" : "strength-training"
        },
        {
          "id" : 14,
          "name" : "HIIT",
          "slug" : "hiit"
        },
        {
          "id" : 15,
          "name" : "Kettlebells",
          "slug" : "kettlebells"
        },
        {
          "id" : 16,
          "name" : "Cycling",
          "slug" : "cycling"
        },
        {
          "id" : 17,
          "name" : "Toning",
          "slug" : "toning"
        },
        {
          "id" : 18,
          "name" : "Tabata",
          "slug" : "tabata"
        }
  ],
  workoutGoals: ['1', '2', '3', '4', '5', '6'],
  disclaimer: {
    headline: 'Recurring billing, cancel anytime.',
    copy: 'Your one-time 7-day free trial starts after you enter payment details, and is for new users only. This will provide unlimited access to Studio Tone It Up’s premium content. Valid credit or debit card required to activate trial. You’ll be charged for the subscription after trial expiration unless you cancel at least one day before free trial ends. Subscription renews automatically unless cancelled at least 24 hours prior to the end of the current subscription period. Cancel or manage your subscription any time. No cancellations or refunds will be provided for any unused portions of a subscription term.'
  },
  footerSignup: {
    headline: 'Stay Connected',
    successMsg: 'Thank you for staying connected!',
    errorMsg: 'Something went wrong. Please try again.'
  }
};

export default COPY;
