import { gql } from 'apollo-boost';

const NAVBAR_USER_QUERY = gql`
  query NAVBAR_USER_QUERY {
    getUserProfile {
      id
      firstName
      displayName
      avatarSmall
      lrId
    }
  }
`;

export default NAVBAR_USER_QUERY;
