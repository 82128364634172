import React from 'react'
import PropTypes from 'prop-types';
import Style from './loader.module.scss';

class Loader extends React.Component {

  static defaultProps = {
    position: null,
    backgroundColor: '#c3ebef',
    size: '40px',
    top: '0',
    left: 'calc(50% - 20px)'
  };

  render() {

    const { position, size, backgroundColor, top, left } = this.props;

    const style = {
      width: size,
      height: size,
      backgroundColor: backgroundColor,
      top: top,
      left: left
    };

    return (
      <div className={Style.loaderContainer} style={{position: position}}>
        <span className={Style.toneItUpLoader} style={style}/>
      </div>
    )
  }
}

export default Loader;

Loader.propTypes = {
  position: PropTypes.string,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string
};
