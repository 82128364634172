import React from 'react';
import PropTypes from 'prop-types';
import Style from './divider.module.scss';

class Divider extends React.Component {
  static defaultProps = {
    text: 'or'
  };

  render() {


    return (

      <div className="grid-x grid-margin-x grid-padding-y text-center">
        <div className={`cell ${Style.divider}`}>
          <small>{this.props.text}</small>
        </div>
      </div>

    );
  }
}

Divider.propTypes = {
  text: PropTypes.string
};

export default Divider;
