/* eslint-disable no-inner-declarations */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import Loader from '../ui/loader';
import AppleLogo from '../../assets/images/AppleLogoSmall.svg';

const LOGIN_VIA_APPLE = gql`
  mutation LOGIN_VIA_APPLE($apple_code: String!) {
    loginViaApple(apple_code: $apple_code) {
      session_token
      through_onboarding
      responses {
        path
        message
        status
      }
    }
  }
`;

const AppleSignIn = ({ lrId }) => {
  const [loginViaApple, { loading }] = useMutation(LOGIN_VIA_APPLE);
  useEffect(() => {
    if (lrId) {
      const cleanCode = lrId.split('=')[1];
      async function login() {
        const res = await loginViaApple({
          variables: { apple_code: cleanCode }
        });
        if (res.data.loginViaApple.session_token) {
          const { session_token } = res.data.loginViaApple;
          localStorage.setItem('tiu_token', session_token);
          navigate('/secure/profile/');
          if (!res.data.loginViaApple.through_onboarding) {
            navigate('/secure/profile/update');
          }
          if (res.data.loginViaApple.through_onboarding) {
            navigate('/secure/profile');
          }
        }
        return null;
      }
      login();
    }
  }, [loginViaApple, lrId]);

  return (
    <>
      <a
        href={`${process.env.SIGNIN_APPLE_HANDLER}?apikey=${process.env.LOGIN_RADIUS_API_KEY}&provider=apple&is_access_token=true&callbacktype=hash&callback=${process.env.SIGNIN_APPLE_CALLBACK}`}
        className="button apple expanded "
      >
        <img src={AppleLogo} alt="Apple" />
        Sign in with Apple
        {loading && <Loader />}
      </a>
    </>
  );
};

AppleSignIn.defaultProps = {
  lrId: ''
};

AppleSignIn.propTypes = {
  lrId: PropTypes.string
};

export default AppleSignIn;
