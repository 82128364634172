/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const GridX = ({ children, containerClasses, gridClasses }) => (
  <section className={`grid-container ${containerClasses || ''}`}>
    <div className={`grid-x ${gridClasses || ''}`}>{children}</div>
  </section>
);

GridX.propTypes = {
  containerClasses: PropTypes.string,
  gridClasses: PropTypes.string
};

export default GridX;
