// Render Prop
import React from 'react';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import Style from './newsletter-form.module.scss';
import COPY from '../../../components/copy';

const NewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
});

class NewsletterForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isAdded: false
    };
  }

  render() {
    return (
      <Formik
        initialValues={this.state}
        validationSchema={NewsletterSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {

          if(_learnq){
              this.setState({
                email: values.email,
                isAdded : true
              });
            _learnq.push(['identify', {'$email' : values.email}]);
            _learnq.push(['track', 'Web Footer Signup']);
          } else {
            setFieldError('email', COPY.footerSignup.errorMsg);
          }

          setSubmitting(false);
        }}
      >
        {({ errors, isSubmitting, values }) => (
          <div className={Style.formContainer}>
            <h3>{COPY.footerSignup.headline}</h3>
            <Form>
              <div className="input-container">
                <Field
                  placeholder="Email address"
                  type="email"
                  name="email"
                  disabled={values.email && this.state.isAdded}
                />
                <ErrorMessage className="tiu-form-error error-message" name="email" component="p" />

                { values.email && this.state.isAdded &&
                  <p className="text-teal-tone-3 text-left"><small>{COPY.footerSignup.successMsg}</small></p>
                }
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

export default NewsletterForm;
