/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Style from './footer.module.scss';
import SocialIcons from '../../social-icons';
import GridX from '../../grid-x';
import NewsletterForm from '../../forms/newsletter-form';

const myToneItUpUrl = 'https://my.toneitup.com/';
const toneItUpUrl = 'https://www.toneitup.com/';

const Footer = () => (
  <div className={Style.wrapper}>
    <div className="grid-container fluid">
      <GridX
        containerClasses="fluid"
        gridClasses={`grid-padding-y flex-dir-column large-flex-dir-row align-justify ${Style.contentContainer}`}
      >
        <div className={Style.inputWrapper}>
          <NewsletterForm />
        </div>

        <div className="list-items">
          <ul className={Style.menuGroup}>
            <li>
              <a
                href={`${myToneItUpUrl}pages/tone-it-up-protein`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Protein
              </a>
            </li>
            <li>
              <a
                href={`${myToneItUpUrl}collections/apparel`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Apparel
              </a>
            </li>
            <li>
              <a
                href={`${myToneItUpUrl}pages/tone-it-up-nutrition-plan-kit`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Nutrition plan
              </a>
            </li>
          </ul>
        </div>

        <div className="list-items">
          <ul className={Style.menuGroup}>
            <li>
              <a
                href={`${myToneItUpUrl}pages/studio-tone-it-up-app`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fitness app
              </a>
            </li>
            <li>
              <a
                href={`${myToneItUpUrl}collections/accessories`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessories
              </a>
            </li>
            <li>
              <a
                href={`${toneItUpUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Articles
              </a>
            </li>
          </ul>
        </div>
        <div className={`list-items ${Style.marginTop}`}>
          <ul className={Style.menuGroup}>
            <li>
              <a
                href={`${toneItUpUrl}lifestyle/about-tone-it-up/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                About us
              </a>
            </li>
            <li>
              <a
                href={`${toneItUpUrl}lifestyle/contact-us/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </a>
            </li>
            <li>
              <a
                href="https://help.toneitup.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help
              </a>
            </li>
          </ul>
        </div>

        <div className="social-links">
          <SocialIcons fill="#000" />
        </div>
      </GridX>
    </div>

    <div className={`grid-container fluid ${Style.subFooterContainer}`}>
      <GridX
        containerClasses={`fluid ${Style.subFooter}`}
        gridClasses="flex-dir-column large-flex-dir-row align-justify"
      >
        <ul
          className={`grid-x flex-dir-column large-flex-dir-row align-justify ${Style.menuGroup}`}
        >
          <li>
            <a
              href="https://my.toneitup.com/pages/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </li>
          <li>
            <a
              href="https://my.toneitup.com/pages/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
        <small>
          &copy;
          {new Date().getFullYear()} Tone It Up. All rights reserved
        </small>
      </GridX>
    </div>
  </div>
);

export default Footer;
