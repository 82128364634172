/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Style from './section-heading.module.scss';

const SectionHeading = ({
  heading,
  headingColorClass,
  paragraph,
  subheading,
  margin,
  mobileTextCenter,
  maxWidth
}) => {
  return (
    <div
      className={`heading fade-in ${Style.heading} ${
        mobileTextCenter ? Style.mobileTextCenter : ''
      } ${maxWidth ? Style.maxWidth : ''}`}
      style={{ margin }}
    >
      <div className={`${Style.heading}`}>
        <h2 className={headingColorClass}>{heading}</h2>
        {paragraph && (
          <p>
            {subheading && (
              <span className="text-black-tone-4">{subheading}</span>
            )}
            {paragraph}
          </p>
        )}
      </div>
    </div>
  );
};

SectionHeading.propTypes = {
  heading: PropTypes.string,
  headingColorClass: PropTypes.string,
  paragraph: PropTypes.string,
  subheading: PropTypes.string,
  margin: PropTypes.string,
  mobileTextCenter: PropTypes.bool,
  maxWidth: PropTypes.bool
};

export default SectionHeading;
