import React from 'react';
import { Link } from 'gatsby';
import Logo from '../../ui/logo';
import Navigation from '../nav';

import Style from './header.module.scss';

const Header = () => (
  <div className={`grid-container fluid ${Style.header}`}>
    <div className="grid-container fluid">
      <header className="grid-x grid-padding align-justify align-middle">
        <Link className={Style.logo} to="/">
          <Logo />
        </Link>
        <div
          className="grid-x align-middle"
          style={{ pointerEvents: 'initial' }}
        >
          <Navigation />
        </div>
      </header>
    </div>
  </div>
);

export default Header;
