import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './layout/header';
import Footer from './layout/footer';
import './layout.css';
import '../assets/scss/app.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          keywords
          description
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: data.site.siteMetadata.description
          },
          { name: 'keywords', content: data.site.siteMetadata.keywords },
          { name: 'robots', content: 'index, follow' }
        ]}
      >
        <html lang="en" />
      </Helmet>

      <div className="global-wrapper">
        <Header />

        <div className="global-wrapper-content">{children}</div>

        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
