import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useQuery } from 'react-apollo';
import ReactModal from 'react-modal';
import Style from './nav.module.scss';
import LoginForm from '../../forms/login-form';
import NAVBAR_USER_QUERY from './data';
import Logo from '../../ui/logo';
import tempProfileImg from '../../../assets/images/profile.png';
import COPY from '../../copy';
import Loader from '../../ui/loader';

const textColor = '#fff';

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const { loading, error, data, client } = useQuery(NAVBAR_USER_QUERY, {
    fetchPolicy: 'network-only'
  });
  if (error) return <span>Error</span>;
  if (loading)
    return <Loader top="calc(50% - 30px)" left="calc(100% - 50px)" />;

  const {
    getUserProfile: {
      firstName = COPY.navigation.defaultUserGreeting,
      avatarSmall,
      lrId
    }
  } = data;
  // do not show "Start Free Trial" button on certain pages
  const pathname = window.location.pathname.split('/');
  const showTrialButtonArray = pathname.filter((u) =>
    ['pick-plan', 'sign-up', 'secure'].includes(u)
  );
  const showTrialButton = !showTrialButtonArray.length > 0;

  const logOut = () => {
    localStorage.removeItem('tiu_token');
    localStorage.removeItem('tiu_offer');
    localStorage.removeItem('selected_subscription');

    client.resetStore();
    navigate('/');
  };

  const menuItems = Object.keys(COPY.navigation.links).map((val) => {
    return (
      <Link
        onClick={() => setMenuOpen(!menuOpen)}
        to={COPY.navigation.links[val]}
        key={Math.random()}
      >
        <div className={Style.menuItem}>{val}</div>
      </Link>
    );
  });

  // logged in user
  if (lrId) {
    return (
      <>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => setMenuOpen(!menuOpen)}
          className="grid-x align-middle"
          style={{ cursor: 'pointer' }}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <p style={{ color: textColor }} className={Style.greeting}>
            {firstName ? `Hey, ${firstName}` : `@username`}
          </p>
          <div
            className={Style.profileImage}
            style={{
              backgroundImage: avatarSmall
                ? `url(${avatarSmall})`
                : `url(${tempProfileImg})`
            }}
          />
        </div>
        <div
          className={
            menuOpen
              ? `${Style.menuContainerOpen} ${Style.menuContainer}`
              : Style.menuContainer
          }
        >
          {menuOpen && (
            <div className={Style.menuItemWrapper}>
              <div className={`mobile-only ${Style.logo}`}>
                <Link
                  to="/"
                  className={Style.logoLink}
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <Logo fill="#000" />
                </Link>
              </div>
              <div className={Style.userInfo}>
                <p className={`${Style.greetingInMenu}`}>
                  {firstName ? `Hey, ${firstName}` : `@username`}
                </p>
              </div>
              <div className={Style.menuList}>{menuItems}</div>
              <button
                type="button"
                className={`button secondary hollow ${Style.buttonLogOut}`}
                onClick={() => logOut()}
              >
                {COPY.navigation.ctaSignOut}
              </button>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        className={Style.logInOut}
        onClick={() => setOpen(true)}
        tabIndex="0"
        style={{ color: textColor }}
      >
        {COPY.navigation.ctaLogin}
      </button>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
        className={Style.modalContent}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 3
          }
        }}
      >
        <LoginForm />
      </ReactModal>
      {showTrialButton && (
        <Link
          className={`button primary desktop-only ${Style.signUp}`}
          to="/pick-plan"
        >
          {COPY.navigation.ctaSignUp}
        </Link>
      )}
    </>
  );
};

export default Navigation;
