import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import Style from './fb-social-login.module.scss';

const LOGIN_VIA_FACEBOOK = gql`
  mutation LOGIN_VIA_FACEBOOK(
    $fb_user: String!
    $fb_token: String!
    $email: String!
  ) {
    loginViaFacebook(fb_user: $fb_user, fb_token: $fb_token, email: $email) {
      sessionToken
      user {
        state
      }
      responses {
        path
        message
        status
      }
    }
  }
`;

const SSO = () => {
  return (
    <Mutation mutation={LOGIN_VIA_FACEBOOK}>
      {(loginViaFacebook) => (
        <FacebookLogin
          appId={process.env.FACEBOOK_APP_ID}
          autoLoad={false}
          callback={async (resultFacebookLogin) => {
            try {
              // Client application interacts directly with LoginRadius to make sure that the potentially new account is created in LR
              const res = await loginViaFacebook({
                variables: {
                  fb_user: resultFacebookLogin.id,
                  fb_token: resultFacebookLogin.accessToken,
                  email: resultFacebookLogin.email
                }
              });
              // set the session token into local storage if success.
              if (res.data.loginViaFacebook.sessionToken) {
                const { sessionToken } = res.data.loginViaFacebook;
                localStorage.setItem('tiu_token', sessionToken);
                // navigate('/secure/profile/update');
                // this.props.toggleModalQuery();
                if (res.data.loginViaFacebook.user.state === 'onboarding') {
                  navigate('/pick-plan');
                } else {
                  navigate('/secure/profile/manage');
                }
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log('Error', e);
            }
          }}
          reauthenticate
          fields="name, email"
          render={(renderProps) => (
            <button
              type="button"
              className={`button expanded button-large facebook ${Style.button}`}
              onClick={(e) => {
                renderProps.onClick();
              }}
            >
              Facebook
            </button>
          )}
        />
      )}
    </Mutation>
  );
};

export default SSO;
