import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { navigate, Link } from 'gatsby';
import SectionHeading from '../../section-heading';
import Divider from '../../ui/divider';
import Loader from '../../ui/loader';
import Style from './login-form.module.scss';
import COPY from '../../copy';
import FbSocialLogin from '../../fb-social-login';
import AppleSignIn from '../../apple-social-login';

const LoginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginViaEmail(email: $email, password: $password) {
      sessionToken
      user {
        firstName
        status
      }
      responses {
        path
        message
        status
      }
    }
  }
`;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required')
});

const LoginForm = ({ lrId }) => {
  const [loginViaEmail] = useMutation(LoginMutation);
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        const response = await loginViaEmail({
          variables: values
        });
        const { loginViaEmail: loginViaEmailResponse } = response.data;
        if (loginViaEmailResponse.sessionToken) {
          localStorage.setItem('tiu_token', loginViaEmailResponse.sessionToken);
          localStorage.setItem('tiu_offer', true);

          if (!loginViaEmailResponse.user.firstName) {
            navigate('/secure/profile/update');
          } else {
            navigate('/secure/profile');
          }
        } else {
          const errorMsg = loginViaEmailResponse.responses
            ? loginViaEmailResponse.responses[0].message
            : '';
          setFieldError('password', errorMsg);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <div className={Style.formContainer}>
          <SectionHeading heading={COPY.logIn.form.headline} />
          <Form>
            <div className="input-container">
              <Field
                placeholder="Email address"
                type="email"
                name="email"
                data-test="input-email"
              />
              <ErrorMessage
                className="tiu-form-error error-message"
                name="email"
                component="p"
              />
            </div>

            <div className="input-container">
              <Field
                placeholder="Password"
                type="password"
                name="password"
                data-test="input-password"
              />
              <ErrorMessage
                className="tiu-form-error error-message"
                name="password"
                component="p"
              />
            </div>

            <div className="grid-x align-middle">
              <div className="cell medium-6">
                <button
                  className={`button primary expanded ${Style.logIn}`}
                  type="submit"
                  disabled={isSubmitting}
                  data-test="button-login"
                >
                  {COPY.logIn.form.ctaLogin}
                  {isSubmitting && <Loader />}
                </button>
              </div>

              <div className="cell medium-4 medium-offset-2 text-center">
                <Link
                  to="/reset-password"
                  onClick={() => navigate('/reset-password')}
                  className="link small"
                >
                  {COPY.logIn.form.ctaForgotPassword}
                </Link>
              </div>
            </div>
          </Form>
          <Divider />
          <FbSocialLogin />
          <AppleSignIn lrId={lrId} />
          <div className="grid-x grid-margin-x grid-padding-y">
            <div className="cell">
              <p>
                {COPY.logIn.form.ctaParagraph}
                <span className={Style.space}>
                  <Link to="/pick-plan" className="link">
                    {COPY.logIn.form.ctaSignUp}
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

LoginForm.defaultProps = {
  lrId: ''
};

LoginForm.propTypes = {
  lrId: PropTypes.string
};

export default LoginForm;
